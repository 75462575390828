import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';

import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import SidebarLayout from './layouts/SidebarLayout';
import Auth from './pages/auth';


const Loader = (Component: React.FC) => (props: any) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);


// General
const Dashboard = Loader(
  lazy(() => import('src/pages/general/dashboard'))
);
const Issues = Loader(
  lazy(() => import('src/pages/general/issues'))
);
const Wellbeing = Loader(
  lazy(() => import('src/pages/general/wellbeing'))
);
const BlogPostScreen = Loader(
  lazy(() => import('src/pages/general/blog'))
);
const ManagePostScreen = Loader(
  lazy(() => import('src/pages/general/blog/ManagePost'))
);

//Certificate

// USERS
const Admins = Loader(
  lazy(() => import('src/pages/users/Admins'))
);
const Agents = Loader(
  lazy(() => import('src/pages/users/Agents'))
);
const Influencers = Loader(
  lazy(() => import('src/pages/users/Influencers'))
);
const AgentProfile = Loader(
  lazy(() => import('src/pages/users/profile'))
);
const Clients = Loader(
  lazy(() => import('src/pages/users/Clients'))
);

// APPLICATIONS
const DocumentForms = Loader(
  lazy(() => import('src/pages/applications/DocumentFormsPage'))
);
const KYCForms = Loader(
  lazy(() => import('src/pages/applications/kyc/KYCForms'))
);
const ManageKYCForm = Loader(
  lazy(() => import('src/pages/applications/kyc/ManageKYCForm'))
);
const ManageDocumentForm = Loader(
  lazy(() => import('src/pages/applications/ManageDocumentForm'))
);
const FormRequests = Loader(
  lazy(() => import('src/pages/applications/FormRequests'))
);
const Jobs = Loader(
  lazy(() => import('src/pages/applications/ViewJobs'))
);
const Configurations = Loader(
  lazy(() => import('src/pages/configurations'))
);

// Status
const Status404 = Loader(
  lazy(() => import('src/pages/status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/pages/status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/pages/status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/pages/status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="login" replace />
      },
      {
        path: 'login',
        element: <Auth type={'login'} />
      },
      {
        path: 'forget-password',
        element: <Auth type={'forget-password'} />
      },
      {
        path: 'reset-password',
        element: <Auth type={'reset-password'} />
      },
      {
        path: 'profile',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <AgentProfile />
          },
        ]
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'general',
    element: <SidebarLayout />,
    children: [
      {
        path: 'dashboard',
        element: <Dashboard />
      },
      {
        path: 'issues',
        element: <Issues />
      },
      {
        path: 'well-being',
        element: <Wellbeing />
      },
      {
        path: 'blogs',
        children: [
          {
            index: true,
            element: <BlogPostScreen />
          },
          {
            path: 'add',
            element: <ManagePostScreen />
          },
          {
            path: ':id',
            element: <ManagePostScreen />
          },
        ]
      }
    ]
  },
  {
    path: 'user',
    element: <SidebarLayout />,
    children: [
      {
        path: 'admins/all',
        element: <Admins />
      },
      {
        path: 'agents/all',
        element: <Agents />
      },
      {
        path: 'agent/profile',
        element: <AgentProfile />
      },
      {
        path: 'clients/all',
        element: <Clients />
      },
      {
        path: 'influencers/all',
        element: <Influencers />
      }
    ]
  },
  {
    path: 'application',
    element: <SidebarLayout />,
    children: [
      {
        path: 'forms',
        children: [
          {
            path: 'document-forms',
            children: [
              {
                index: true,
                element: <DocumentForms />,
              },
              {
                path: 'add',
                element: <ManageDocumentForm />
              },
              {
                path: ':state/:id',
                element: <ManageDocumentForm />
              },
            ]
          },
          {
            path: 'kyc',
            children: [
              {
                index: true,
                element: <KYCForms />,
              },
              {
                path: 'add',
                element: <ManageKYCForm />
              },
              {
                path: ':state/:id',
                element: <ManageKYCForm />
              },
            ]
          },
        ]
      },
      {
        path: 'requests',
        element: <FormRequests />
      },
      {
        path: 'jobs',
        element: <Jobs />
      }
    ]
  },
  {
    path: 'configurations',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Configurations />
      }
    ]
  },
];

export default routes;

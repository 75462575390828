import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Admin } from "src/models/admin";
import { Subscription } from "src/models/applicant";
import { Influencer } from "src/models/influencer";
import axios from '../axios';

export const getAllAgents = createAsyncThunk(
  'users/getAllAgents',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get('/admin/agents');
      return fulfillWithValue(data.agents);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAgentStats = createAsyncThunk(
  'users/getAgentStats',
  async (agent_id: string | number, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/admin/agent/stats/${agent_id}`);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAllClients = createAsyncThunk(
  'users/getAllClients',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/admin/clients`);
      return fulfillWithValue(data.clients);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAllInfluencers = createAsyncThunk(
  'users/getAllInfluencers',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/admin/influencers`);
      return fulfillWithValue(data.influencers);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const influencerActiveness = createAsyncThunk(
  'users/influencerActiveness',
  async ({ inf_id, is_active }: { inf_id: number, is_active: boolean }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.put(`/admin/influencers/${inf_id}/activeness`, { is_active });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const addInfluencer = createAsyncThunk(
  'users/addInfluencer',
  async (influencer: Influencer, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/admin/influencers`, influencer);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAllWellbeingMsgs = createAsyncThunk(
  'users/getAllWellbeingMsgs',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/admin/clients/wellbeing`);
      return fulfillWithValue(data.wellbeing);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);


export const replyWellbingMsg = createAsyncThunk(
  'users/replyWellbingMsg',
  async ({ message, id }: { message: string, id: number }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.put(`/admin/clients/wellbeing/reply/${id}`, { message });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);


export const getAllIssues = createAsyncThunk(
  'users/getAllIssues',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/admin/clients/issues`);
      return fulfillWithValue(data.issues);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const replyIssueById = createAsyncThunk(
  'users/replyIssueById',
  async ({ message, id }: { message: string, id: number }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.put(`/admin/clients/issues/reply/${id}`, { message });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const markIssueAsResolved = createAsyncThunk(
  'users/markIssueAsResolved',
  async ({ state, id }: { state: string, id: number }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.put(`/admin/clients/issues/resolved/${id}`, state);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteIssueById = createAsyncThunk(
  'users/deleteIssueById',
  async (id: number, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`/admin/clients/issues/${id}`);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const agentActiveness = createAsyncThunk(
  'users/agentActiveness',
  async ({ agent_id, is_active }: { agent_id: number, is_active: boolean }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/admin/agent/activeness/${agent_id}`, { is_active });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const clientActiveness = createAsyncThunk(
  'users/clientActiveness',
  async ({ client_id, is_active }: { client_id: number, is_active: boolean }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/admin/clients/activeness/${client_id}`, { is_active });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteClientById = createAsyncThunk(
  'users/deleteClientById',
  async (client_id: number, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`/admin/clients/${client_id}`);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const emailUser = createAsyncThunk(
  'users/emailUser',
  async ({ message, email, id, role }: { message: string, email: string, id: number, role: 'user' | 'agent' | 'influencer' }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/admin/email/user`, { email, message, user_id: id, role });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const subscribeUserToPackage = createAsyncThunk(
  'users/subscribeUserToPackage',
  async (subscription: Subscription, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/admin/clients/subscribe`, subscription);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteAgentById = createAsyncThunk(
  'users/deleteAgentById',
  async (agent_id: number, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`/admin/agent/${agent_id}`);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const verifyAgent = createAsyncThunk(
  'users/verifyAgent',
  async (agent_id: number, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/admin/agent/accept/${agent_id}`);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const requestAdditionFiles = createAsyncThunk(
  'users/requestAdditionFiles',
  async ({ files, additional_info, id }:
    { additional_info: string, id: number, files: string[] }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/admin/clients/request/${id}`, { files, additional_info });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const rejectAgent = createAsyncThunk(
  'users/rejectAgent',
  async ({ reason, id }: { reason: string, id: number }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/admin/agent/reject/${id}`, { reason });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const addAdmin = createAsyncThunk(
  'users/addAdmin',
  async (admin: Admin, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/admin`, admin);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAllAdmins = createAsyncThunk(
  'users/getAllAdmins',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/admin`);
      return fulfillWithValue(data.admins);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);